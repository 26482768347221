<template>
    <div class="invoice flex-1 white-back">
                <div class="flex-cc" style="height: 100%;" v-if="tax_param.length==0">
                    <el-empty :image="imgPath" description="暂时没有发票信息，下单成功会自动保存发票信息哦~" :image-size="467" />
                </div>
     <div v-else style="padding-bottom: 10px;">
         <el-form  label-width="180px" disabled>
             <h1 class="h1">发票信息</h1>

             <h1 style="border-top: 1px solid #eee;"></h1>
             <div v-for="(item,index) in tax_param" :key="index" style="border-bottom: 10px dashed #eee;padding-top: 20px;">
                 <el-form-item label="发票抬头：">
                     <el-input v-model="item.tax_param.companyName"></el-input>
                 </el-form-item>
                 <el-form-item label="纳税人识别号：">
                     <el-input v-model="item.tax_param.taxNumber"></el-input>
                 </el-form-item>
                 <el-form-item label="注册地址：">
                     <el-input v-model="item.tax_param.address"></el-input>
                 </el-form-item>
                 <el-form-item label="注册电话：">
                     <el-input v-model="item.tax_param.telphone"></el-input>
                 </el-form-item>
                 <el-form-item label="开户银行：">
                     <el-input v-model="item.tax_param.bank"></el-input>
                 </el-form-item>


                 <el-form-item label="银行账号：">
                     <el-input v-model="item.tax_param.account"></el-input>
                 </el-form-item>
             </div>


         </el-form>
     </div>

    </div>
</template>

<script>
    import imgPath from "@/assets/img/no_receipt.png";
    import {oFapia} from '@/request/api'

    export default {
        name: "index",
        data() {
            return {
                imgPath,
                tax_param:[]
            }
        },
        methods:{
            async getFapia(){
                let res=await oFapia();
                this.tax_param = res.data;
            }
        },
        created() {
            this.getFapia()
        }
    }
</script>

<style scoped lang="scss">
    .r-0{
        border-radius: none;
    }
    .invoice {
        /deep/ .el-empty__description p {
            color: $black;
        }
        /deep/ .el-form-item__label{font-size: 16px;}
        /deep/ .first{margin-bottom: 10px;}
        /deep/ .el-input__inner{
          border-radius: $n-r;
            width: $input-width;
        }
    }
    .invoice {
        .h1{font-weight: initial;padding: 15px 0 10px 20px;font-size: 20px;}
    }
</style>